import { createApp } from 'vue'

import i18n from './i18n.js'
import mixins from './mixins.js'
import router from './router.js'
import App from './App.vue'

import './assets/content-style.css'
import './assets/global.css'

import SiteLogo from './components/SiteLogo.vue'
import Breadcrumb from './components/Breadcrumb.vue'
import LanguageSelector from './components/LanguageSelector.vue'
import PlaceholderMessage from './components/PlaceholderMessage.vue'
import NotifyModal from './components/NotifyModal.vue'
import ConfirmModal from './components/ConfirmModal.vue'

import firebase from 'firebase/app'
firebase.initializeApp(require(`${process.env.VUE_APP_SECRET_CONFIG}`).default)

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe()
            resolve(user)
        }, reject)
    })
}

const app = createApp(App)

app.component('SiteLogo', SiteLogo)
app.component('Breadcrumb', Breadcrumb)
app.component('LanguageSelector', LanguageSelector)
app.component('PlaceholderMessage', PlaceholderMessage)
app.component('NotifyModal', NotifyModal)
app.component('ConfirmModal', ConfirmModal)

app.mixin(mixins)
app.use(router)
app.use(i18n)
app.mount('#app')