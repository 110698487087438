const firebaseConfig = {
    apiKey: "AIzaSyAw7ljNv-0vU-nsijwf15FaWHDWBabmdeg",
    authDomain: "jnpaccounting-5fe5f.firebaseapp.com",
    databaseURL: "https://jnpaccounting-5fe5f.firebaseio.com",
    projectId: "jnpaccounting-5fe5f",
    storageBucket: "jnpaccounting-5fe5f.appspot.com",
    messagingSenderId: "1036594281119",
    appId: "1:1036594281119:web:e8fe2b31ca1dfcb0"
}

export default firebaseConfig