<template>
    <div class="dropdown">
        <button type="button" id="languageSelector" data-bs-toggle="dropdown" aria-expanded="false" class="btn-change-lang text-secondary d-flex ms-3 py-2">
            <i class="fas fa-globe my-auto"></i>
            <i class="fas fa-chevron-down fa-xs ms-2 my-auto"></i>
        </button>
        <div aria-labelledby="languageSelector" class="dropdown-menu dropdown-menu-end shadow">
            <span @click="selectLanguage(language.locale)" v-for="language in supportLanguages" :key="language.index" class="dropdown-item small py-2 fw-light cursor-arrow">
                {{language.title}} <span class="text-uppercase">({{language.locale}})</span>
            </span>
        </div>
    </div>
</template>
<style scoped>
.btn-change-lang {
    border: 0;
    background: inherit
}
.btn-change-lang:focus {
    outline: none;
}
</style>
<script>
export default {
    data() {
        return {
            supportLanguages: [
                {title: "English", locale: "en", flag: ""},
                {title: "ไทย", locale: "th", flag: ""}
            ]
        }
    },
    computed: {
        selectedLanguage() {
            return localStorage.getItem('locale')
        }
    },
    methods: {
        setLocale(locale) {
            localStorage.setItem('locale', locale)
        },
        selectLanguage(locale) {
            const savedLocale = localStorage.getItem('locale')
            this.setLocale(locale)
            this.$i18n.locale = locale
        }
    }
}
</script>