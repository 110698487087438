import { createI18n } from 'vue-i18n/index'

const savedLocale = localStorage.getItem('locale')
const currentLocale = (savedLocale) ? savedLocale : navigator.language

const i18n = createI18n({
    locale: currentLocale,
    fallbackLocale: 'en'
})

export default i18n