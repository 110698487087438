import { createRouter, createWebHistory } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

const FullOptionTemplate = () => import('./panels/FullOption.vue')
const BlankTemplate = () => import('./panels/Blank.vue')
const AdminPanel = () => import('./panels/AdminPanel.vue')

const Homepage = () => import('./pages/Homepage/Homepage.vue')
const AboutUs = () => import('./pages/AboutUs/AboutUs.vue')
const Laws = () => import('./pages/Laws/Laws.vue')
const News = () => import('./pages/News/News.vue')
const NewsArticle = () => import('./pages/News/Article.vue')
const XeroWorkshop = () => import('./pages/XeroWorkshop/XeroWorkshop.vue')

const Services = () => import('./pages/Services/Services.vue')
const Service01 = () => import('./pages/Services/articles/01/Article.vue')
const Service02 = () => import('./pages/Services/articles/02/Article.vue')
const Service03 = () => import('./pages/Services/articles/03/Article.vue')
const Service04 = () => import('./pages/Services/articles/04/Article.vue')
const Service05 = () => import('./pages/Services/articles/05/Article.vue')
const Service06 = () => import('./pages/Services/articles/06/Article.vue')
const Service07 = () => import('./pages/Services/articles/07/Article.vue')
const Service08 = () => import('./pages/Services/articles/08/Article.vue')

const SignIn = () => import('./pages/SignIn/SignIn.vue')

const AdminDashboard = () => import('./pages/Admin/Dashboard/Dashboard.vue')
const ContentManager = () => import('./pages/Admin/Manager/Content/ContentManager.vue')
const LawsManager = () => import('./pages/Admin/Manager/Laws/LawsManager.vue')
const NewsManager = () => import('./pages/Admin/Manager/News/NewsManager.vue')
const NewsArticleCreator = () => import('./pages/Admin/Manager/News/ArticleForm.vue')

const ERR404 = () => import('./pages/Error/404.vue')

const serviceRoutes = [
    {path: 'services/', name: 'Services', component: Services},
    {path: 'services/company-registration', name: 'Service01', component: Service01},
    {path: 'services/company-liq-and-dis', name: 'Service02', component: Service02},
    {path: 'services/outsourced-acct-and-tax-compliance', name: 'Service03', component: Service03},
    {path: 'services/annual-statutory-audit', name: 'Service04', component: Service04},
    {path: 'services/visa-and-work-permit', name: 'Service05', component: Service05},
    {path: 'services/th-personal-income-tax', name: 'Service06', component: Service06},
    {path: 'services/tax-refund-and-audit-assistance', name: 'Service07', component: Service07},
    {path: 'services/xero-cloud-software', name: 'Service08', component: Service08},
]

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: FullOptionTemplate,
            children: [
                {path: '', name: 'Homepage', component: Homepage},
                {path: 'about', name: 'AboutUs', component: AboutUs},
                {path: 'laws', name: 'Laws', component: Laws},
                {path: 'news', name: 'News', component: News},
                {path: 'news/:id', name: 'NewsArticle', component: NewsArticle},
                {path: 'xero-workshop', name: 'XeroWorkshop', component: XeroWorkshop},
                {path: '/404', name: "Error404", component: ERR404},
                ...serviceRoutes,
            ]
        },
        {
            path: '/',
            component: BlankTemplate,
            children: [
                {path: '/sign-in', name: 'SignIn', component: SignIn},
            ]
        },
        {
            path: '/admin',
            component: AdminPanel,
            children: [
                {path: '', name: 'AdminDashboard', component: AdminDashboard},
                {path: 'content', name: 'ContentManager', component: ContentManager},
                {path: 'content/laws', name: 'LawsManager', component: LawsManager},
                {path: 'content/news', name: 'NewsManager', component: NewsManager},
                {path: 'content/news/form', name: 'NewsArticleCreator', component: NewsArticleCreator, props: route => ({id: route.query.id})}
            ],
            meta: {
                requireAuth: true
            }
        },
        // Redirect non valid path.
        {path: '/:pathMath(.*)*', redirect: '/404'}
    ],
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
              el: to.hash,
              behavior: 'smooth',
            }
        } else {
            return { top: 0 }
        }
    }
})

router.beforeEach(async (to, from, next) => {

    const requireAuth = to.matched.some(record => record.meta.requireAuth)
    if (requireAuth && !(await firebase.getCurrentUser())) {
        next({name: 'SignIn'})
    } else {
        // If path to news has no specific category then pass it to hightlight category
        // if (to.fullPath === '/news') next('/news?category=highlight')

        // If path to services has no specific category then pass it to first service
        if (to.name === 'Services') next({name: 'Service01'})
        else next()
    }

})

export default router