<template>
    <div class="d-flex flex-column align-items-center text-muted">
        <i :class="icon" class="fa-4x"></i>
        <div class="mt-3">{{$t("message", message)}}</div>
    </div>
</template>
<i18n>
{
    "en": {
        "message": "{en}"
    },
    "th": {
        "message": "{th}"
    }
}
</i18n>
<script>
export default {
    props: {
        message: {
            type: Object,
            require: true,
            validator(value) {
                const keys = Object.keys(value)
                return keys.includes('en') && keys.includes('th')
            }
        },
        icon: {
            type: String,
            default: 'fas fa-chalkboard'
        }
    }
}
</script>