<template>
    <div>
        <ol class="breadcrumb small">
            <li v-for="(path, index) in paths" :key="index" :class="{active: lastPath(index, paths.length)}" class="breadcrumb-item">
                <span v-if="lastPath(index, paths.length)">{{path}}</span>
                <router-link v-else :to="`/${path}`">{{path}}</router-link>
            </li>
        </ol>
    </div>
</template>
<script>
export default {
    data() {
        return {
            paths: []
        }
    },
    created() {
        let paths = this.$route.path.split('/')
        paths.shift()
        paths = paths.map(path => decodeURIComponent(path))
        this.paths = paths
    },
    methods: {
        lastPath(index, length) {
            return index + 1 === length
        }
    }
}
</script>