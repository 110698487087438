<template>
    <div class="modal fade" id="confirmationModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0 rounded">
                <div class="modal-body p-4">
                    Confirm ?
                </div>
            </div>
        </div>
    </div>
</template>