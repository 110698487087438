import firebase from 'firebase/app'
import 'firebase/auth'

import moment from 'moment'

export default {
    data() {
        return {
            _placeholder: {
                banner: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fnews%2Fimgs.001.jpeg?alt=media&token=13252633-b7f0-4beb-aa49-1be4cbb0f2c0"
            }
        }
    },
    created() {
        // Check locale in local storage, if undefined then create a new one.
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', 'en')
        }

        moment.locale(localStorage.getItem('locale'))
    },
    methods: {
        _formatDateRelation(timestamp) {
            const time = moment.unix(timestamp).format("DD/MM/YYYY")
            return time
        },
        authStatePromise() {
            return new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    (user) ? resolve(user) : reject()
                    unsubscribe()
                })
            })
        },
        async _isAuth() {
            const status = await this.authStatePromise().then(() => true, () => false)
            return status
        },
        async _getUser() {
            const info = await this.authStatePromise().then(res => res, () => {})
            return info
        },
        async _signOut() {
            await firebase.auth().signOut()
            location.reload()
        },
        nullUserException() {
            firebase.auth().signOut()
            this.$router.push({name: 'SignIn'})
        }
    }
}